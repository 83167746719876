/* eslint-disable @typescript-eslint/camelcase */
export const GA_TRACKING_ID = 'G-RK90KV5REZ'
export const AW_CONVERSION_ID = 'AW-595563012'

declare global {
  interface Window {
    gtag: any
  }
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: string) => {
  window?.gtag('config', GA_TRACKING_ID, {
    page_path: url,
    allow_enhanced_conversions: true,
  })
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
type EventProps = {
  action: string
  id: string
}

export const recordEvent = ({ action, id }: EventProps) => {
  if (window.gtag)
    window.gtag('event', action, {
      ['send_to']: `${AW_CONVERSION_ID}/${id}`,
    })
}
