export const LANG_LOCALES = ['en', 'pseudo', 'bn-BD', 'uk-UA', 'ja-JP'] as const

export const I18N_LANGUAGES = ['English', 'বাংলা', '日本語'] as const

export const I18N_LANGUAGE_CODES = ['EN', 'BN', 'JA'] as const

export const I18N_COUNTRIES = [
  'Global',
  'Pakistan',
  'বাংলাদেশ',
  'Bangladesh',
  '日本',
  'Japan',
] as const

export const LOCALE = {
  pseudo: {
    langLocale: 'pseudo',
    isProd: false,
    language: 'English',
    country: 'Global',
    languageCode: 'EN',
  },
  translate: {
    langLocale: 'uk-UA',
    isProd: false,
    language: 'English',
    country: 'Global',
    languageCode: 'EN',
  },
  en: {
    langLocale: 'en',
    isProd: true,
    country: 'Global',
    language: 'English',
    languageCode: 'EN',
  },
  'en-bd': {
    langLocale: 'en',
    isProd: true,
    country: 'Bangladesh',
    language: 'English',
    languageCode: 'EN',
  },
  'bn-bd': {
    langLocale: 'bn-BD',
    isProd: true,
    country: 'বাংলাদেশ',
    language: 'বাংলা',
    languageCode: 'BN',
  },
  'en-ca': {
    langLocale: 'en',
    isProd: true,
    country: 'Canada',
    language: 'English',
    languageCode: 'EN',
  },
  'en-jp': {
    langLocale: 'en',
    isProd: true,
    country: 'Japan',
    language: 'English',
    languageCode: 'EN',
  },
  'ja-jp': {
    langLocale: 'ja-JP',
    isProd: true,
    country: '日本',
    language: '日本語',
    languageCode: 'JA',
  },
  'en-np': {
    langLocale: 'en',
    isProd: true,
    country: 'Nepal',
    language: 'English',
    languageCode: 'EN',
  },
  'en-ng': {
    langLocale: 'en',
    isProd: true,
    country: 'Nigeria',
    language: 'English',
    languageCode: 'EN',
  },
  'en-pk': {
    langLocale: 'en',
    isProd: true,
    country: 'Pakistan',
    language: 'English',
    languageCode: 'EN',
  },
  'en-us': {
    langLocale: 'en',
    isProd: true,
    country: 'United States',
    language: 'English',
    languageCode: 'EN',
  },
  'en-ug': {
    langLocale: 'en',
    isProd: true,
    country: 'Uganda',
    language: 'English',
    languageCode: 'EN',
  },
}

/** Note: copy of this contant is also present in server.js */
export const COUNTRY_WISE_LOCALES = {
  PK: ['en-pk'],
  US: ['en-us'],
  CA: ['en-ca'],
  NG: ['en-ng'],
  UG: ['en-ug'],
  NP: ['en-np'],
  BD: ['bn-bd', 'en-bd'],
  JP: ['en-jp', 'ja-jp'],
}

// Add localized pages here which are different enough to have
// their own link as canonical link
export const LOCALE_WISE_CANONICAL_LOCALIZED_PATH_GLOBS = {
  'en-pk': [] as string[],
  'en-bd': [] as string[],
  'en-us': [] as string[],
  'en-ca': [] as string[],
  'en-ng': [] as string[],
  'en-ug': [] as string[],
  'en-np': [] as string[],
  'bn-bd': ['/', '/login*', '/register*', '/trial-class/**', '/batches/**'],
  'en-jp': [] as string[],
  'ja-jp': ['/', '/login*', '/register*', '/trial-class/**', '/batches/**'],
}

export const USER_LOCALE_PREFERENCE_COOKIE = 'user_locale_preference'
