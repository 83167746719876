import { isEmptyObject } from '@lib/utils/common'
import { t } from '@lingui/macro'
import validator from 'validator'

export const validatePhone = (phone: string) => {
  // Expects the phone with dial code without spaces, and no plus sign
  // That is the format in which we get from `PhoneInput` component we use
  const errors = {}
  if (validator.isEmpty(phone)) {
    errors['phone'] = [t`Please enter your phone number`]
  }
  // eslint-disable-next-line @typescript-eslint/camelcase
  if (!validator.isNumeric(phone, { no_symbols: true })) {
    errors['phone'] = [t`Please enter a valid phone number`]
  }

  if (!validator.isLength(phone, { min: 5, max: 15 })) {
    errors['phone'] = [t`Please enter a valid phone number`]
  }
  return errors
}

export const validatePassword = (password: string) => {
  const errors = {}
  if (validator.isEmpty(password)) {
    errors['password'] = [t`Please enter a password`]
  }
  if (!validator.isLength(password, { min: 8, max: 128 })) {
    errors['password'] = [t`Password must be more than 8 characters`]
  }
  if (validator.isNumeric(password)) {
    errors['password'] = [t`Password must contain at least one letter`]
  }
  return errors
}

export const validatePasswords = (
  password: string,
  passwordConfirmation: string
) => {
  const errors = []
  const passwordErrors = validatePassword(password)
  const passwordConfirmationErrors = validatePassword(passwordConfirmation)

  if (!isEmptyObject(passwordErrors)) {
    errors.push(...Object.values(passwordErrors))
  }
  if (!isEmptyObject(passwordConfirmationErrors)) {
    errors.push(...Object.values(passwordConfirmationErrors))
  }
  if (password !== passwordConfirmation) {
    errors.push(t`Passwords do not match`)
  }

  return errors
}
