import Spinner from '@components/common/spinner'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

const RoutingSpinner = () => {
  const [isRouting, setIsRouting] = useState(false)
  const router = useRouter()

  useEffect(() => {
    const handleStart = () => setIsRouting(true)

    const handleStop = () => setIsRouting(false)

    router.events.on('routeChangeStart', handleStart)
    router.events.on('routeChangeComplete', handleStop)
    router.events.on('routeChangeError', handleStop)

    return () => {
      router.events.off('routeChangeStart', handleStart)
      router.events.off('routeChangeComplete', handleStop)
      router.events.off('routeChangeError', handleStop)
    }
  }, [router])

  if (!isRouting) return null

  return (
    <div
      className="sticky z-50 bg-white rounded-md shadow-lg w-12 h-12 flex justify-center items-center"
      style={{ bottom: '3rem', left: '3rem' }}
    >
      <Spinner classes="text-orange w-8 h-8" />
    </div>
  )
}

export default RoutingSpinner
