import { PASSWORD_RESET_MODAL_ID } from '@common/constants'
import { CClient, flattenErrorsDictValues } from '@common/utils'
import Button from '@components/atoms/button'
import Modal from '@components/common/modal'
import { validatePasswords } from '@containers/common/validators'
import { BROWSER_STORAGE_KEYS } from '@lib/data/browser-storage-keys'
import { getAPIErrorString } from '@lib/utils/common'
import { setToLocalStorage } from '@lib/utils/local-storage'
import { captureException, captureMessage } from '@sentry/node'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { AlertTriangle } from 'react-feather'
import swal from 'sweetalert'
import PasswordInput from './password-input'

type Props = {
  isOpen: boolean
  hasPassword: boolean
  setIsPassModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}
const PasswordResetModal = ({
  isOpen,
  hasPassword,
  setIsPassModalOpen,
}: Props) => {
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [errors, setErrors] = useState([])
  const [loading, setLoading] = useState(false)

  const router = useRouter()

  const handlePasswordReset = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setLoading(true)
    setErrors([])

    try {
      // TODO: Add frontend validation
      const validationErrors = validatePasswords(password, passwordConfirmation)

      if (validationErrors.length > 0) {
        setErrors(validationErrors)
        return
      }

      const apiEndpoint = '/api/v1/password-reset/'
      const apiBody = {
        password: password,
        password2: passwordConfirmation,
      }
      const response = await CClient(apiEndpoint, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(apiBody),
      })
      if (!response.ok) {
        const data = await response.json()
        const errors = flattenErrorsDictValues(data)
        setErrors(errors)
        throw new Error(await getAPIErrorString(response, apiEndpoint, apiBody))
      }

      setToLocalStorage(BROWSER_STORAGE_KEYS.HAS_PASSWORD, 'true', () => {
        captureMessage(
          `Failed to set "${BROWSER_STORAGE_KEYS.HAS_PASSWORD}" to true in local storage`
        )
      })

      setIsPassModalOpen(false)
      swal({
        icon: 'success',
        title: 'Success',
        text: 'Password set successfully',
      }).then((hasClosed) => {
        if (hasClosed) {
          router.reload()
        }
      })
    } catch (error) {
      captureException(error)
    } finally {
      setLoading(false)
    }
  }

  const closeModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setPassword('')
    setPasswordConfirmation('')
    setErrors([])
    setIsPassModalOpen(false)
  }

  return (
    <div>
      <Modal
        id={PASSWORD_RESET_MODAL_ID}
        isOpen={isOpen}
        setIsOpen={setIsPassModalOpen}
        isCrossIconShown
        shouldCloseOnEsc={false}
      >
        <p className="text-xl font-600 mb-8 text-grey">
          {hasPassword ? 'Reset' : 'Set'} password for your account
        </p>
        <form
          className="flex flex-col space-y-5 text-grey"
          onSubmit={handlePasswordReset}
        >
          <fieldset className="flex flex-col space-y-2">
            <label className="text-lg font-600">Enter new password</label>
            <PasswordInput
              id="password_1"
              name="password_1"
              placeholder="Enter new password"
              value={password}
              onChange={(e) => setPassword(e.currentTarget.value)}
            />
          </fieldset>

          <fieldset className="flex flex-col space-y-2">
            <label className="text-lg font-600">Confirm new password</label>
            <PasswordInput
              id="password_2"
              name="password_2"
              placeholder="Re-enter your new password"
              value={passwordConfirmation}
              onChange={(e) => setPasswordConfirmation(e.currentTarget.value)}
            />
          </fieldset>
          <div>
            {errors.length > 0 &&
              errors.map((error, i) => (
                <div
                  className="flex items-center space-x-2 bg-red-50 border-l-4 border-orange py-1"
                  key={i}
                >
                  <AlertTriangle size={15} className="text-red-200 ml-2" />
                  <p className="text-sm inline-block">{error}</p>
                </div>
              ))}
          </div>
          <Button className="w-full" type="submit" loading={loading}>
            Set new password
          </Button>

          <div className="text-center">
            <Button
              className="inline-block"
              variant="link"
              onClick={closeModal}
              type="button"
            >
              Cancel
            </Button>
          </div>
        </form>
      </Modal>
    </div>
  )
}

export default PasswordResetModal
