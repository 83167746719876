import { hasRoute } from '@common/utils'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'

const ALLOWED_PATH = [
  '/competitions/**/problems/**',
  '/competitions/**/problems',
  '/competitions/**/competition-leaderboard',
  '/competitions/**/submissions',
]

const CompetitionHelloBar = () => {
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => setIsMounted(true), [])

  const { pathname } = useRouter()
  if (hasRoute(pathname, ALLOWED_PATH))
    return (
      isMounted && (
        <>
          <div
            style={{ margin: '1px 0' }}
            className="md:top-0 z-10 px-3 lg:text-lg text-center bg-orange text-white font-600 lg:font-700"
          >
            Warning: Do not switch tabs during the competition! You may be
            logged out if you switch tabs.
          </div>
        </>
      )
    )

  return null
}

export default React.memo(CompetitionHelloBar)
