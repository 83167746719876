import { hasRoute } from '@common/utils'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

const ZokoWidget = () => {
  const { pathname } = useRouter()
  const [shouldLoadScript, setShouldLoadScript] = useState(false)

  const loadScript = () => {
    window['__idWdgt9362__'] = '2728ebd2-16ff-11ee-9de3-42010a02090f'

    const script = document.createElement('script')
    script.src = 'https://atlantis.live.zoko.io/scripts/main.js'
    script.defer = true
    document.head.appendChild(script)

    return () => {
      document.head.removeChild(script)
      delete window['__idWdgt9362__']
    }
  }

  useEffect(() => {
    if (hasRoute(pathname, ['/student/dashboard/**', '/student/dashboard**'])) {
      setShouldLoadScript(true)
    } else {
      setShouldLoadScript(false)
    }
  }, [pathname])

  useEffect(() => {
    if (shouldLoadScript) {
      return loadScript()
    }
    if (!shouldLoadScript) {
      const injectedDiv = document.getElementById(
        'zoko-8aec0844-b70f-11ec-b909-0242ac120002'
      )
      if (injectedDiv) {
        injectedDiv.remove()
      }
    }
  }, [shouldLoadScript])

  return null
}

export default ZokoWidget
