import { UserProps } from '@contexts/session'

export const FLAGGED_FEATURES = {
  GAMIFICATION: {
    users: ['student@codingal.com', 'student1@codingal.com'],
    flag: 'gamification',
  },
  COURSE_MODULES: {
    users: ['student@codingal.com', 'student1@codingal.com'],
    flag: 'course-modules',
  },
  QUIZ_COMPETITION: {
    emailDomain: '@codingal.com',
    flag: 'quiz-competition',
  },
  STUDENT_CERTIFICATE: {
    users: ['bakareroheet@gmail.com'],
    flag: 'student-certificate',
  },
  DASHBOARD_LEADERBOARD: 'dashboard-leaderboard',
  DASHBOARD_RANK: 'dashboard-rank',
  DASHBOARD_POINTS_LOG: 'dashboard-points-log',
}

export const getFeaturesForUser = (userData: UserProps) => {
  const features = []
  const { loggedIn } = userData

  if (loggedIn) {
    // Object.keys(FLAGGED_FEATURES).forEach((feature) => {
    //   if (FLAGGED_FEATURES[feature]?.users?.includes(email)) {
    //     features.push(FLAGGED_FEATURES[feature].flag)
    //   }
    // })

    features.push(FLAGGED_FEATURES.GAMIFICATION.flag)
    features.push(FLAGGED_FEATURES.COURSE_MODULES.flag)
    features.push(FLAGGED_FEATURES.STUDENT_CERTIFICATE.flag)
    // features.push(FLAGGED_FEATURES.DASHBOARD_LEADERBOARD)
    // features.push(FLAGGED_FEATURES.DASHBOARD_POINTS_LOG)
    // features.push(FLAGGED_FEATURES.DASHBOARD_RANK)
  }
  features.push(FLAGGED_FEATURES.QUIZ_COMPETITION.flag)
  return features
}
