import { USER_COUNTRY_FROM_IP_COOKIE } from '@lib/constants/common'
import {
  Country,
  getAllCountries,
  getCountriesForTimezone,
  getCountry,
} from 'countries-and-timezones'
import { parseCookies } from 'nookies'

export const getCountryFromCountryName = (countryName: string): Country =>
  Object.values(getAllCountries()).filter(
    (country) => country.name === countryName
  )?.[0] || {
    id: '' as any,
    name: '',
    timezones: [],
  }

export const getCountryFromLocale = (locale: string) => {
  const countryCode = locale?.split('-')[1]
  const country = getCountry(countryCode)
  return country
}

export const getCountryForTimezone = (timezone: string) => {
  const countries = getCountriesForTimezone(timezone)
  if (countries.length === 1) return countries[0]
  // Handling the case where a timezone can have multiple countries associated to it.
  // If that is the case we use IP based country. FYI, IP detected countries are stored
  // in cookies.
  const countryCodeViaCookie = parseCookies()?.[USER_COUNTRY_FROM_IP_COOKIE]
  return getCountry(countryCodeViaCookie) || countries[0]
}
