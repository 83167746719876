import React, { Dispatch, SetStateAction, useCallback } from 'react'
import { X } from 'react-feather'
import ReactModal from 'react-modal'

type Props = {
  children: React.ReactNode | React.ReactNode[]
  isOpen: boolean
  setIsOpen?: Dispatch<SetStateAction<boolean>>
  onClose?: () => void
}

const Popup = ({ children, isOpen, setIsOpen, onClose }: Props) => {
  const handleClosePopup = useCallback(() => {
    onClose?.()
    setIsOpen(false)
  }, [onClose])
  return (
    <ReactModal
      isOpen={isOpen}
      style={{
        overlay: { position: 'static' },
        content: {
          position: 'fixed',
          right: 0,
          bottom: 0,
          top: 'auto',
          left: 'auto',
          margin: '16px',
          boxShadow:
            '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
          padding: '10px',
          zIndex: 2147483001,
        },
      }}
    >
      <div className="flex flex-col">
        {setIsOpen && (
          <button
            onClick={handleClosePopup}
            className="absolute top-0 right-0 w-5 mt-2 mr-3 cursor-pointer md:mt-4 md:mr-5 text-grey-800 focus:outline-none hover:text-orange"
          >
            <X />
          </button>
        )}
        <div>{children}</div>
      </div>
    </ReactModal>
  )
}

export default Popup
