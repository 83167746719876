import { CClient, flattenErrorsDictValues } from '@common/utils'
import {
  CELERY_TASK_STATUS,
  MAXMIND_GEOIP2_DB_FALLBACK_FILE_NAME,
  MAXMIND_GEOIP2_DB_FILE_NAME,
} from '@lib/constants/common'
import {
  CeleryTaskStatusResponseType,
  CheckIsDOMNodeChildOfOptionsType,
} from '@lib/types/common'
import { captureException, captureMessage } from '@sentry/node'
import { IncomingMessage } from 'http'
import { GetServerSidePropsContext, GetServerSidePropsResult } from 'next'
import { NextApiRequestCookies } from 'next/dist/server/api-utils'
import Router from 'next/router'
import { parseCookies } from 'nookies'

export const redirectToForGetInitialProps = (ctx, redirectUrl) => {
  if (typeof window === 'undefined') {
    // This is running on server-side
    const finalRedirectUrl =
      ctx.locale !== ctx.defaultLocale
        ? `/${ctx.locale}${redirectUrl}`
        : redirectUrl
    ctx.res.writeHead(302, { location: finalRedirectUrl })
    ctx.res.end()
  } else {
    // This is running on client-side
    Router.push(redirectUrl)
  }
  return {}
}

export const getServerSideRedirectResult = (
  ctx: GetServerSidePropsContext,
  redirectUrl: string
): GetServerSidePropsResult<any> => {
  const finalRedirectUrl =
    typeof window === 'undefined' && ctx.locale !== ctx.defaultLocale
      ? `/${ctx.locale}${redirectUrl}`
      : redirectUrl

  return {
    redirect: {
      destination: finalRedirectUrl,
      permanent: false,
    },
  }
}

export const removeLocaleFromPathname = (pathname: string, locale: string) =>
  pathname?.split('/')?.[1] === locale ? pathname?.split(locale)[1] : pathname

export const isEmptyObject = (obj: Record<string, any>) => {
  return Object.keys(obj).length === 0
}

export const getSegmentUserType = (
  isPaidUser: boolean,
  isCompetitionUser: boolean,
  loggedIn = true
) => {
  if (!loggedIn) return 'none'
  if (isPaidUser) return 'paid'
  if (isCompetitionUser) return 'other'
  return 'trial'
}

export const convertToKebabCase = (input: string) =>
  input.toLowerCase().trim().split(' ').join('-')

/**
 * @param taskId Celery task id for which we want to know the status of
 * @param pollingInterval Unit: ms. Default value is 1000.
 * @param timeoutLimit Unit: ms. Default value is 120000.
 * @returns Returns true if the status is successful else false
 */
export const pollCeleryTaskStatus = async (
  taskId: string,
  pollingInterval = 1000,
  timeoutLimit = 120000
): Promise<boolean> => {
  try {
    const response = await CClient(`/api/v1/celery-tasks/${taskId}/`)
    if (!response.ok)
      throw new Error(`${response.status}: ${response.statusText}`)
    const data: CeleryTaskStatusResponseType = await response.json()

    if (CELERY_TASK_STATUS.SUCCESS.includes(data.status)) return true
    else if (CELERY_TASK_STATUS.FAILED.includes(data.status)) return false
    else if (timeoutLimit <= 0)
      throw new Error(
        `Polling timeout: Polling ran for ${timeoutLimit}ms with no result`
      )
    else {
      // Waiting for `pollingInterval` milliseconds before calling the function again.
      await new Promise((resolve) => setTimeout(resolve, pollingInterval))
      return await pollCeleryTaskStatus(
        taskId,
        pollingInterval,
        timeoutLimit - pollingInterval
      )
    }
  } catch (error) {
    console.error(error)
  }
  return false
}

/**
 * @param width Original width of the element (image)
 * @param height Original height of the element (image)
 * @param scale The scale factor to multiply with. Final Dimensions(w * scale, h * scale)
 * @returns
 */
export const getDimensions = (width: number, height: number, scale = 1) => ({
  width: width * scale,
  height: height * scale,
})

/** Extracts and returns error message from the response. If no error
 * message is present, returns status code.
 */
export const flattenErrorsDictFromResponse = async (response: Response) => {
  const errorData = await response?.json()
  const errors = flattenErrorsDictValues(errorData)
  return errors?.length > 0 ? errors?.join('\n') : response?.status?.toString()
}

/**
 * @param {Response} response - The response from the API call.
 * @param {string} url - The URL that was requested.
 * @param [requestBody=null] - The request body that was sent to the API.
 * @returns A string that contains the error message, the url, the request body, and the auth token.
 */
export const getAPIErrorString = async (
  response: Response,
  url: string,
  requestBody: Record<string, any> | string = null
) => {
  const flattenedErrorDict = response
    ? await flattenErrorsDictFromResponse(response)
    : 'No response!'
  const requestBodyString = requestBody
    ? typeof requestBody === 'string'
      ? requestBody
      : JSON.stringify(requestBody)
    : 'NO REQUEST BODY'
  const token = parseCookies().token
  return `${flattenedErrorDict} | URL: ${url} | REQUEST_BODY: ${requestBodyString} | TOKEN: ${token}`
}

/**
 * It checks if a DOM node is a child of another DOM node whose id or className is given
 * @returns a boolean
 */
export const checkIsDOMNodeChildOf = ({
  node,
  parentClassName = null,
  parentId = null,
}: CheckIsDOMNodeChildOfOptionsType) => {
  const allParentNodeIds = []
  const allParentNodeClassNames = []

  let currNode = node

  while (currNode) {
    if (parentId && currNode.id) allParentNodeIds.push(currNode.id)
    if (parentClassName && currNode.className)
      allParentNodeClassNames.push(currNode.className)
    currNode = currNode.parentNode
  }

  return (
    allParentNodeIds.includes(parentId) ||
    allParentNodeClassNames.includes(parentClassName)
  )
}

export const formatCountryQuery = (countryCode: string) => {
  return countryCode ? `&country=${countryCode}` : ''
}

//get all indexes of a particular value in an array
export const getAllIndices = (arr, val) => {
  const indices = []
  for (let i = 0; i < arr.length; i++) {
    if (arr[i] === val) indices.push(i)
  }
  return indices
}

//frequecy map of all the elements in an array
export const getFrequency = (array) => {
  const map = {}
  array.forEach((item) => {
    if (map[item]) {
      map[item]++
    } else {
      map[item] = 1
    }
  })
  return map
}

export const playSound = async (sound: HTMLAudioElement) => {
  try {
    await sound?.play()
  } catch (error) {
    console.error(error)
  }
}

export const getFirstNameAndLastName = (name: string) => {
  const indexOfSpace = name?.indexOf(' ')
  if (indexOfSpace === -1)
    return {
      firstName: name,
      lastName: '',
    }

  return {
    firstName: name?.substring(0, indexOfSpace).toLowerCase(),
    lastName: name?.substring(indexOfSpace + 1).toLowerCase(),
  }
}

export const parseIpFromReq = (
  req: IncomingMessage & {
    cookies: NextApiRequestCookies
  }
) => {
  let forwarded = req?.headers?.['x-forwarded-for']
  if (typeof forwarded !== 'string') forwarded = forwarded?.[0]
  return forwarded?.split(',').shift() || req.socket?.remoteAddress
}

const getMaxmindIpDatabaseFileName = async () => {
  const fs = await import('fs')

  let fileName = ''
  if (fs.existsSync(MAXMIND_GEOIP2_DB_FILE_NAME))
    fileName = MAXMIND_GEOIP2_DB_FILE_NAME
  else {
    fileName = MAXMIND_GEOIP2_DB_FALLBACK_FILE_NAME
    const message =
      'Latest Maxmind GeoIP2 database file not found. Using fallback.'
    captureMessage(message)
  }

  return fileName
}

export const getCountryFromIP = async (ctx): Promise<string | null> => {
  try {
    if (typeof window !== 'undefined') return null
    const { req } = ctx
    const ip = parseIpFromReq(req)
    if (!ip) return null
    const fileName = await getMaxmindIpDatabaseFileName()
    const maxmind = await import('@maxmind/geoip2-node')
    const reader = await maxmind.Reader.open(fileName)
    const countryData = reader.country(ip)
    return countryData?.country?.isoCode
  } catch (error) {
    captureException(error)
  }
}
