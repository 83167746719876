import { destroyCookie } from 'nookies'

import { CClient } from '@common/utils'

export const userFetcher = async (url: string, token?: string, ctx?: any) => {
  const unauthData = { loggedIn: false }

  // Early exit in case there is no `token` in cookie
  if (token === undefined) return unauthData

  const headers = {
    Authorization: `Token ${token}`,
  }

  const res = await CClient(url, {
    method: 'GET',
    headers,
  })

  if (res.ok) {
    return { ...(await res.json()), ...{ loggedIn: true } }
  }

  // Result was not ok, i.e. the user has an invalid `token` in cookie. Let's destroy
  // that so that next time we perform that early-exit declared above
  destroyCookie(ctx, 'token', { path: '/' })
  return unauthData
}
