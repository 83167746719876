import { LOCALE_REDIRECTION_EXCLUSION_GLOBS } from '@common/constants'
import { USER_COUNTRY_FROM_IP_COOKIE } from '@lib/constants/common'
import {
  COUNTRY_WISE_LOCALES,
  LOCALE,
  USER_LOCALE_PREFERENCE_COOKIE,
} from '@lib/constants/i18n'
import { LinguiJSMessageDescriptor } from '@lib/types/common'
import { removeLocaleFromPathname, getCountryFromIP } from '@lib/utils/common'
import type { I18n } from '@lingui/core'
import { t } from '@lingui/macro'
import { captureException } from '@sentry/node'
import { en, bn, uk, ja } from 'make-plural/plurals'
import minimatch from 'minimatch'
import { parseCookies, setCookie } from 'nookies'

const redirectToLocale = (ctx, locale: string) => {
  const pathname = removeLocaleFromPathname(ctx.asPath, ctx.locale)
  const redirectionUrl = `/${locale}/${pathname}`
  ctx.res.writeHead(302, { location: redirectionUrl })
  ctx.res.end()
}

/**
 * If the user is on the wrong locale, redirect them to the correct locale
 * @param ctx - The context object that Next.js provides to the getServerSideProps function.
 * @param {string} country - The country code of the user.
 */
const redirectToCorrectLocaleBasedOnCountry = (ctx, country: string) => {
  // Function should run only on server side
  if (typeof window !== 'undefined') return
  // Locale is not present for the country and user is on the defaultLocale
  if (
    !Object.keys(COUNTRY_WISE_LOCALES).includes(country) &&
    ctx.locale === ctx.defaultLocale
  )
    return
  // Locale is present for the country and user is on the correct locale
  if (COUNTRY_WISE_LOCALES?.[country]?.includes(ctx.locale)) return

  // Locale miss-match is present. Either one is true:
  // 1. Locale exists for the country and the user is on the wrong locale
  // 2. Locale does not exist for the country and the user is not on the default locale
  const pathname = removeLocaleFromPathname(ctx.asPath, ctx.locale)
  let redirectionUrl = pathname
  if (Object.keys(COUNTRY_WISE_LOCALES).includes(country)) {
    redirectionUrl = `/${COUNTRY_WISE_LOCALES[country][0]}${pathname}`
  }
  ctx.res.writeHead(302, { location: redirectionUrl })
  ctx.res.end()
}

/**
 * It checks if the user is visiting the default locale of the site, if not it returns.
 * If the user is visiting the default locale, it returns.
 * Else it checks if the user has a cookie with preferred locale, redirects to that locale and returns.
 * Else it checks if the user has a cookie with their country, if not it gets
 * the country from the user's IP and sets a cookie with the country. Then it redirects the user to the
 * correct locale based on their country
 * @param ctx - The context object that Next.js passes to the getInitialProps function.
 * @returns a Promise
 */
export const handleLocaleRedirections = async (ctx) => {
  try {
    // Function should run only on server side
    if (typeof window !== 'undefined') return
    // Skip checks for users visiting localized version of the site
    // This will let users from other regions view a locale
    if (ctx.locale !== ctx.defaultLocale) return
    const pathname = ctx.asPath
    if (
      LOCALE_REDIRECTION_EXCLUSION_GLOBS.some((glob) =>
        minimatch(pathname, glob)
      )
    )
      return

    const cookies = parseCookies(ctx)

    const preferredLocale = cookies?.[USER_LOCALE_PREFERENCE_COOKIE]
    if (preferredLocale) {
      // At this point current locale is default locale. If preferred
      // locale is also default locale then return early
      if (preferredLocale === ctx.defaultLocale) return
      redirectToLocale(ctx, preferredLocale)
      return
    }

    const country = cookies?.[USER_COUNTRY_FROM_IP_COOKIE]
    if (country) {
      redirectToCorrectLocaleBasedOnCountry(ctx, country)
      return
    }
    const countryFromIP = await getCountryFromIP(ctx)
    if (!countryFromIP) throw new Error('Users IP can not be determined')
    setCookie(ctx, USER_COUNTRY_FROM_IP_COOKIE, countryFromIP, {
      // setting max age of the cookie to 1 year
      maxAge: 60 * 60 * 24 * 365,
      path: '/',
    })
    redirectToCorrectLocaleBasedOnCountry(ctx, countryFromIP)
  } catch (error) {
    captureException(error)
  }
}

// Anounce which locales we are going to use and connect them to approprite plural rules
export const initTranslation = (i18n: I18n): void => {
  i18n.loadLocaleData({
    en: { plurals: en },
    'bn-BD': { plurals: bn },
    'ja-JP': { plurals: ja },
    pseudo: { plurals: en },
    'uk-UA': { plurals: uk },
  })
}

export const getLangLocaleFromLocale = (locale: string) =>
  LOCALE[locale]?.langLocale

export const loadTranslation = async (locale: string) => {
  const isProd = process.env.NODE_ENV === 'production'
  const langLocale = getLangLocaleFromLocale(locale)
  if (!langLocale) return null
  const data = isProd
    ? await import(`@translations/locales/${langLocale}/messages`)
    : await import(
        `@lingui/loader!@translations/locales/${langLocale}/messages.po`
      )

  return data.messages
}

export const msgT = (message: string | LinguiJSMessageDescriptor) => {
  if (!message) return ''
  if (typeof message === 'string') return message
  return t({
    id: message.id,
    context: message.context,
    values: message.values,
  })
}

export const formatLocale = (locale) => {
  if (!locale) return ''

  const parts = locale.split('-')
  if (parts.length === 2) {
    return `${parts[0].toLowerCase()}-${parts[1].toUpperCase()}`
  }

  // Return the locale as is for single-part locales like 'en', 'en-US
  return locale.toLowerCase()
}
