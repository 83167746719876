import Spinner from '@components/common/spinner'
import classNames from 'classnames'

type ButtonElementProps = JSX.IntrinsicElements['button']

type IntentType = 'primary' | 'warning' | 'success' | 'danger'
export type SizeType = 'md' | 'lg' | 'xl'
export type ButtonProps = ButtonElementProps & {
  disabled?: boolean
  loading?: boolean
  variant?: 'link' | 'outline' | 'solid'
  size?: SizeType
  intent?: IntentType
}

const THEME = {
  base: {
    solid: 'inline-block align-middle border rounded focus:outline-none',
    outline: 'inline-block align-middle border rounded focus:outline-none',
    link: 'focus:outline-none hover:underline',
  },
  state: {
    loading: {
      solid: '',
      outline: '',
      link: '',
    },
    disabled: {
      solid: 'opacity-50 cursor-not-allowed text-white',
      outline: 'bg-grey-600 hover:bg-grey-600 cursor-not-allowed text-white',
      link: 'cursor-not-allowed text-grey-700 hover:no-underline',
    },
  },
  size: {
    md: {
      solid: 'font-700 px-6 lg:px-10 py-2 h-11',
      outline: 'font-700 px-6 lg:px-10 py-2 h-11',
      link: 'font-600',
    },
    lg: {
      solid: 'font-600 px-6 py-2 h-12 text-lg',
      outline: 'font-600 px-6 py-2 h-12 text-lg',
      link: 'text-lg font-700',
    },
    xl: {
      solid: 'font-600 px-16 h-13 text-lg',
      outline: 'font-600 px-16 h-13 text-lg',
      link: 'text-lg font-700',
    },
  },
  // This is done because a variant will be a combination of the variant type and intent
  // eg. Orange outline button, Green outline button, Orange solid button etc.
  variants: {
    primary: {
      solid: 'bg-orange text-white hover:bg-orange-500 active:bg-orange-800',
      outline: 'text-orange hover:bg-orange-200',
      link: 'text-orange',
    },
    success: {
      solid: 'bg-green-200 hover:bg-green-100 text-white',
      link: 'text-green-200',
    },
  },
  spinner: {
    solid: 'text-white',
    outline: 'text-white',
    link: 'text-orange',
  },
}
const Button = ({
  children,
  className,
  variant = 'solid',
  size = 'md',
  intent = 'primary',
  loading,
  disabled,
  ...props
}: ButtonProps) => {
  return (
    <button
      className={classNames(
        THEME.base[variant],
        THEME.size[size][variant],
        THEME.variants[intent][variant],
        loading && THEME.state.loading[variant],
        disabled && THEME.state.disabled[variant],
        className
      )}
      disabled={disabled}
      {...props}
    >
      {loading ? (
        <Spinner
          classes={classNames('h-5 w-5 mx-auto', THEME.spinner[variant])}
        />
      ) : (
        children
      )}
    </button>
  )
}

export default Button
