import { LOCAL_STORAGE_UTM_PARAMS_KEY } from '@lib/constants/tracking'
import { getFromLocalStorage } from '@lib/utils/local-storage'
import { NextRouter } from 'next/router'
import { parseCookies, setCookie } from 'nookies'

const UTM_PARAMETERS = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
]

export const storeLastTouchUTMParametersInSessionCookie = (
  router: NextRouter
) => {
  UTM_PARAMETERS.forEach((paramName) => {
    const paramValue = router.query[paramName]
    if (paramValue !== undefined) {
      setCookie({}, paramName, paramValue.toString(), {
        maxAge: 24 * 60 * 60,
        path: '/',
      })

      if (paramName === 'utm_source') {
        if (!parseCookies()['utm_source'])
          import('@sentry/node').then((s) =>
            s.captureMessage(`${paramName} = ${paramValue} not captured!`)
          )
      }
    }
  })
}

export const generateUTMParametersDictFromCookie = () => {
  const returnObj = {}
  const cookies = parseCookies()
  UTM_PARAMETERS.forEach((paramName) => {
    const paramValue = cookies[paramName]
    if (paramValue !== undefined) {
      returnObj[paramName] = paramValue
    }
  })
  if (Object.keys(returnObj).length === 0) return null
  return returnObj
}

export const generateUTMParametersDict = (router: NextRouter) => {
  const returnObj = {}
  UTM_PARAMETERS.forEach((paramName) => {
    let paramValue = router.query[paramName]

    if (paramValue === undefined) return

    // Handling case: Multiple instances of the same UTM param key is present in the query
    // In that case, use the last query param value.
    // Example: `/utm_medium=test&utm_medium=test2`
    if (Array.isArray(paramValue)) paramValue = paramValue.pop()

    returnObj[paramName] = paramValue
  })

  if (Object.keys(returnObj).length === 0) return null
  const prevUTMParameters = getUTMParametersDictFromLocalStorage()
  return { ...prevUTMParameters, ...returnObj }
}

export const getUTMParametersDictFromLocalStorage = () => {
  const utmParametersDict = getFromLocalStorage(LOCAL_STORAGE_UTM_PARAMS_KEY)
  if (!utmParametersDict) return null

  const utmParametersObj = JSON.parse(utmParametersDict)
  if (!utmParametersObj) return null

  // Handling case: Multiple instances of the same UTM param key is present in the query
  // In that case, use the last query param value.
  // Example: `/utm_medium=test&utm_medium=test2`
  for (const [key, value] of Object.entries(utmParametersObj)) {
    if (Array.isArray(value)) utmParametersObj[key] = value.pop()
  }

  return utmParametersObj
}
