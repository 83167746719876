// Async version of lib/utils/country for dynamic import
import { USER_COUNTRY_FROM_IP_COOKIE } from '@lib/constants/common'
import { parseCookies } from 'nookies'

export const getCountryFromCountryName = async (countryName: string) => {
  const { getAllCountries } = await import('countries-and-timezones')
  const country = Object.values(getAllCountries()).filter(
    (country) => country.name === countryName
  )?.[0]
  const fallbackCountry = {
    id: '' as any,
    name: '',
    timezones: [],
  }
  return country || fallbackCountry
}

export const getCountryFromLocale = async (locale: string) => {
  const { getCountry } = await import('countries-and-timezones')
  const countryCode = locale?.split('-')[1]
  const country = getCountry(countryCode)
  return country
}

export const getCountryForTimezone = async (timezone: string) => {
  const { getCountry, getCountriesForTimezone } = await import(
    'countries-and-timezones'
  )
  const countries = getCountriesForTimezone(timezone)
  if (countries.length === 1) return countries[0]
  // Handling the case where a timezone can have multiple countries associated to it.
  // If that is the case we use IP based country. FYI, IP detected countries are stored
  // in cookies.
  const countryCodeViaCookie = parseCookies()?.[USER_COUNTRY_FROM_IP_COOKIE]
  return getCountry(countryCodeViaCookie) || countries[0]
}
