export const AFFILIATE_PARTNERS = {
  SVG: 'svg',
  AFFLE: 'affle',
  ICUBE: 'icubes',
  SHAREASALE: 'shareasale',
}

export const LOCAL_STORAGE_UTM_PARAMS_KEY = 'utm-parameters'

export const SIGNUP_REFERRER_LOCAL_STORAGE_KEY = 'signupReferrer'
