import React, { FC, ChangeEvent, useState } from 'react'
import { Eye, EyeOff } from 'react-feather'

type Props = {
  id: string
  name: string
  placeholder: string
  value: string
  required?: boolean
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  onEnter?: () => void
}

const PasswordInput: FC<Props> = ({
  id,
  name,
  placeholder,
  value,
  required,
  onChange,
  onEnter,
}) => {
  const [showPassword, setShowPassword] = useState(false)

  const handleEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.preventDefault()

    if (onEnter && e.key === 'Enter') {
      onEnter()
    }
  }

  return (
    <div className="flex w-full relative">
      <input
        className="border border-grey-500 rounded py-2 px-4 focus:border-orange focus:outline-none placeholder-grey-800 w-full"
        type={showPassword ? 'text' : 'password'}
        id={id}
        name={name}
        placeholder={placeholder}
        autoComplete="off"
        value={value}
        onChange={(e) => onChange(e)}
        required={required}
        onKeyUp={handleEnter}
      />
      <div onClick={() => setShowPassword(!showPassword)}>
        {!showPassword && (
          <Eye size={15} className="absolute right-0 mt-3 mr-3 text-grey-800" />
        )}
        {showPassword && (
          <EyeOff
            size={15}
            className="absolute right-0 mt-3 mr-3 text-grey-800"
          />
        )}
      </div>
    </div>
  )
}

export default PasswordInput
