export const openGraphConfig = <TextType = string>(
  url: string,
  title: TextType,
  description?: TextType,
  imageUrl?: string
) => {
  const config = {
    openGraph: {
      type: 'website',
      title,
      description,
      url,
      // eslint-disable-next-line @typescript-eslint/camelcase
      site_name: 'Codingal',
      images: [
        {
          url: imageUrl || 'https://fastly.codingal.com/images/meta-image.png',
          alt: 'Codingal',
        },
      ],
    },
    twitter: {
      handle: '@codingal',
      cardType: 'summary_large_image',
    },
    facebook: {
      appId: '373752980313511',
    },
  }
  return config
}

export const defaultSeo = {
  titleTemplate: '%s | Codingal',
  title: 'Online coding classes for kids',
  description:
    'Codingal is on a mission to provide coding education to every K-12 student. Kids start learning to code via online coding classes conducted by best teachers and using an engaging curriculum.',
  ...openGraphConfig(
    'https://www.codingal.com/',
    'Online coding classes for kids',
    'Codingal is on a mission to provide coding education to every K-12 student. Kids start learning to code via online coding classes conducted by best teachers and using an engaging curriculum.'
  ),
}

export const geoLongLandingPageSeo = (urlPath: string) => ({
  title:
    'Try a free coding class for your child | Codingal is the #1 Coding Platform for kids',
  description:
    'Let your child learn the fundamentals of coding. They can build animations, websites and mobile app with expert computer science teachers.',
  ...openGraphConfig(
    `https://www.codingal.com/lp/${urlPath}/`,
    'Try a free coding class for your child | Codingal is the #1 Coding Platform for kids',
    'Let your child learn the fundamentals of coding. They can build animations, websites and mobile app with expert computer science teachers.',
    `${process.env.NEXT_PUBLIC_STATIC_URL}/images/book-trial-class/meta-image.png`
  ),
})

export const codingWorkshopSeo = {
  title:
    'Free coding workshop for kids and teens | Codingal is the #1 Coding Platform for kids',
  description:
    'Let your child learn the fundamentals of coding. They can build animations, websites and mobile app with expert computer science teachers.',
  ...openGraphConfig(
    `https://www.codingal.com/lp/coding-workshop/`,
    'Free coding workshop for kids and teens | Codingal is the #1 Coding Platform for kids',
    'Let your child learn the fundamentals of coding. They can build animations, websites and mobile app with expert computer science teachers.',
    `${process.env.NEXT_PUBLIC_STATIC_URL}/images/book-trial-class/meta-image.png`
  ),
}

export const codingForKidsGlobalSeo = {
  title: 'Free Online Coding For Kids',
  description:
    'Codingal provides the best coding classes for kids and teens. Our courses are for ages 6-13 year kids that help them grasp the skills to prepare for the competition.',
  ...openGraphConfig(
    'https://www.codingal.com/coding-for-kids/',
    'Free Online Coding For Kids',
    'Codingal provides the best coding classes for kids and teens. Our courses are for ages 6-13 year kids that help them grasp the skills to prepare for the competition.',
    `${process.env.NEXT_PUBLIC_STATIC_URL}/images/coding-for-kids/meta-image.png`
  ),
}

export const NOINDEX_PAGE_GLOBS = [
  '/**/buy-course',
  '/**/checkout',
  '/**/thank-you',
  '/**/lp/**',
  '/exclusive-coding-package/**',
  '/payment-partners/**',
  '/@**/**', // Noindex all user profiles pages
  '/kids-teens-coding-camps/registration-success**', //  coding camp - Registration success page
]
