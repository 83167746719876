import Button from '@components/atoms/button'
import Popup from '@components/common/popup'
import useDetectCountry from '@hooks/useDetectCountry'
import {
  COUNTRY_WISE_LOCALES,
  USER_LOCALE_PREFERENCE_COOKIE,
} from '@lib/constants/i18n'
import { getCountryFromLocale } from '@lib/utils/country'
import {
  getFromLocalStorage,
  setToLocalStorage,
} from '@lib/utils/local-storage'
import { Trans } from '@lingui/macro'
import { useRouter } from 'next/router'
import { parseCookies, setCookie } from 'nookies'
import { useCallback, useEffect, useMemo, useState } from 'react'

const TIMEZONE_LOCALE_COUNTRY_CHECK_LOCALSTORAGE_KEY =
  'timezone-locale-country-checked'

const setTimezoneLocaleCountryCheckToLocalstorage = () => {
  setToLocalStorage(
    TIMEZONE_LOCALE_COUNTRY_CHECK_LOCALSTORAGE_KEY,
    'true',
    () => console.error('Failed to store to locale storage')
  )
}

const VerifyLocale = () => {
  const router = useRouter()
  const {
    countryCode: countryCodeFromTimezone,
    countryName: countryNameFromTimezone,
  } = useDetectCountry({
    isDeterminedViaSystemTimezoneOnly: true,
  })

  const countryFromLocale = useMemo(
    () => getCountryFromLocale(router.locale),
    []
  )

  const [showPopup, setShowPopup] = useState(false)

  useEffect(() => {
    // Check if a locale exists for the country from tz
    if (
      !Object.keys(COUNTRY_WISE_LOCALES).some(
        (countryCode) => countryCode === countryCodeFromTimezone
      )
    )
      return

    const cookies = parseCookies()
    const isLocalePreferenceSet = cookies?.[USER_LOCALE_PREFERENCE_COOKIE]
    if (isLocalePreferenceSet) return

    const isTimezoneLocaleCountryChecked = getFromLocalStorage(
      TIMEZONE_LOCALE_COUNTRY_CHECK_LOCALSTORAGE_KEY
    )
    if (isTimezoneLocaleCountryChecked) return

    const countryCodeFromLocale = countryFromLocale?.id
    if (countryCodeFromLocale === countryCodeFromTimezone) {
      setTimezoneLocaleCountryCheckToLocalstorage()
      return
    }

    // Country from timezone and country from locale miss-matches. Also,
    // country from timezone is supported by one of the locales
    setShowPopup(true)
  }, [countryCodeFromTimezone])

  // Close popup when user changes locale via
  // language switcher
  useEffect(() => {
    return () => {
      setShowPopup(false)
    }
  }, [router.locale])

  const handleClosePopup = useCallback(() => {
    setTimezoneLocaleCountryCheckToLocalstorage()
    setShowPopup(false)
  }, [])

  const handleSwitchLocale = useCallback(() => {
    const nextLocale = COUNTRY_WISE_LOCALES[countryCodeFromTimezone]?.[0]
    if (!nextLocale) return

    const { pathname, asPath, query } = router
    setTimezoneLocaleCountryCheckToLocalstorage()
    setCookie({}, USER_LOCALE_PREFERENCE_COOKIE, nextLocale, {
      maxAge: 365 * 24 * 60 * 60,
      path: '/',
    })
    router.push({ pathname, query }, asPath, { locale: nextLocale })
    setShowPopup(false)
  }, [router.pathname, countryCodeFromTimezone])

  if (showPopup)
    return (
      <Popup
        isOpen={showPopup}
        setIsOpen={setShowPopup}
        onClose={handleClosePopup}
      >
        <div className="flex flex-col p-5 space-y-5 max-w-sm">
          <p className="pr-3">
            <Trans id="verifyLocalePopup.heading">
              We think you are in {countryNameFromTimezone}. Update your
              location?
            </Trans>
          </p>
          <div className="flex space-x-3">
            <Button variant="outline" onClick={handleClosePopup}>
              {countryFromLocale?.name || 'Global'}
            </Button>
            <Button onClick={handleSwitchLocale}>
              {countryNameFromTimezone}
            </Button>
          </div>
        </div>
      </Popup>
    )
  return null
}

export default VerifyLocale
